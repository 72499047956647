//
//  Amasty Slick Theme Styles
//  ____________________________________________

//
//  Variables
//  --------------------------------------------

@amslick-arrow__background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMi40QTEgMSAwIDAgMSAxLjYuMmwxMiAxMGExIDEgMCAwIDEgMCAxLjZsLTEyIDEwYTEgMSAwIDAgMS0xLjItMS42TDExLjMzMyAxMSAuNCAxLjhBMSAxIDAgMCAxIC4yLjR6IiBmaWxsPSIjQzRDNEM0Ii8+PC9zdmc+') center no-repeat;
@amslick-arrow__background__hover: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMi40QTEgMSAwIDAgMSAxLjYuMmwxMiAxMGExIDEgMCAwIDEgMCAxLjZsLTEyIDEwYTEgMSAwIDAgMS0xLjItMS42TDExLjMzMyAxMSAuNCAxLjhBMSAxIDAgMCAxIC4yLjR6IiBmaWxsPSIjMTYxNjE2Ii8+PC9zdmc+') center no-repeat;
@amslick-indent__zero: 0;
@amslick-indent__l: @indent__l;
@amslick-indent__m: 20px;
@amslick-indent__s: 15px;
@amslick-indent__xs: @indent__xs;
@amslick-list__size: 25px;
@amslick-dot__size: 20px;
@amslick-opacity__default: .75;
@amslick-opacity__on-hover: 1;
@amslick-opacity__not-active: .25;
@amslick-dot__color: @color-black;

//
//  Keyframes
//  --------------------------------------------

@keyframes loading {
    0% {
        transform: translate(-50%,-50%) rotate(0);
    }

    100% {
        transform: translate(-50%,-50%) rotate(359.9deg);
    }
}
